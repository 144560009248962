<template>
  <component
    v-bind="$attrs"
    :is="tag"
    class="admin-link"
    :href="adminURL"
    target="_blank"
    @click.stop
  >
    <slot />

    <v-icon size="x-small">
      mdi-open-in-new
    </v-icon>
  </component>
</template>

<script>
export default {
  name: 'admin-link',
  props: {
    to: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      default: 'a',
    }
  },
  computed: {
    adminURL() {
      return this.$api.v1.getAdminURL(this.to)
    }
  }
}
</script>

<style scoped lang="scss">
.admin-link {
  text-decoration: none;
}

.v-icon {
  margin-left: 3px;
  position: relative;
  top: -5px;
}
</style>
